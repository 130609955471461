<template>
  <List el="xsm" style="max-width: 1000px">
    <List el="column-between xsm">
      <Text el="h2">Profile</Text>
      <div>
        <Btn
        v-if="saveProfileStatus == null"
          @click="updateUser"
          >SAVE</Btn
        >
        <Btn
        v-else disabled
          >Loading...</Btn
        >
      </div>
      
    </List>

    <List el="xxsm p-lg" class="section">
      <Text el="h3">Contact Details</Text>
      <hr el>
        <!-- <label>
          <List el="xxsm">
            <Text el="h5" style="user-select:none;">Image URL</Text>
            <input type="text" placeholder="URL" title="URL" v-model="product.img" />
          </List>
        </label> -->
        <!-- <div>
          <img :src="userInput.photoURL" alt="profile image">
        </div> -->
        <label>
          <List el="xxsm">
            <Text el="h5" style="user-select:none;">Full Name</Text>
            <input type="text" placeholder="Full Name" title="Full Name" v-model="userInput.displayName" />
          </List>
        </label>
        <label>
          <List el="xxsm">
            <Text el="h5" style="user-select:none;">Email</Text>
            <input type="text" placeholder="Email" title="Email" v-model="userInput.email" />
          </List>
        </label>
        <label>
          <List el="xxsm">
            <Text el="h5" style="user-select:none;">Phone</Text>
            <input type="text" placeholder="Phone" title="Phone" v-model="userInput.phone" />
          </List>
        </label>
        <label>
          <List el="xxsm">
            <Text el="h5" style="user-select:none;">Address</Text>
            <input type="text" placeholder="Address" title="Address" v-model="userInput.address" />
          </List>
        </label>
        <!-- <label>
          <List el="xxsm">
            <Text el="h5" style="user-select:none;">Description</Text>
            <textarea type="text" placeholder="Description" title="Description" v-model="product.description"></textarea>
          </List>
        </label> -->
        <!-- <label>
          <List el="column xxsm">
            <Text el="bold" style="user-select:none;">$</Text>
            <input type="number" placeholder="Dollars" title="Cost" v-model="product.cost" />
          </List>
        </label>
        <label>
          <List el="column xxsm">
            <Text el="bold" style="user-select:none;">Quantity</Text>
            <input type="number" placeholder="Number" title="Quantity" v-model="product.quantity" style="width:100%; text-align: right;" />
            <Icon name="add" @click.stop="product.quantity++" style="user-select:none;" />
            <Icon name="remove" @click.stop="product.quantity--" style="user-select:none;" />
          </List>
        </label> -->
      </List>

  </List>

  <!-- <Modal title="Create Product" ref="productModal">
      
      <List el="column xxsm" style="justify-content: end;">
        <Btn el="2ry" @click="productModal.toggle()">CANCEL</Btn>
        <Btn @click="addProduct" v-if="saveProfileStatus == null && product.id == null">CREATE</Btn>
        <Btn @click="updateProduct" v-else-if="saveProfileStatus == null  && product.id != null">SAVE</Btn>
        <Btn
          v-else
          disabled
          >Loading...</Btn
        >
      </List>
    </Modal> -->

</template>
<script setup>
import {ref, onMounted, 
  // computed, reactive
} from 'vue'
// import { useStore } from 'vuex'
import dialog from '@/components/dialog';
import API from '../components/API';
// import Icon from '../components/btn/Icon.vue';
// const store = useStore();
// import router from "@/router";
// let filter = require("lodash/filter");
// let lowerCase = require("lodash/lowerCase");
// let includes = require("lodash/includes");
// let cloneDeep = require("lodash/cloneDeep");
// import FullCalendar from "../components/FullCalendar.vue";

const saveProfileStatus = ref(null)

const userInput = ref({
  displayName:'',
  email:'',
  phone:'',
  address:'',
});

// const defaultUserInput = cloneDeep(userInput);

const updateUser = async () => {
  saveProfileStatus.value = 'loading';
  if(
    userInput.value.firstname == '' ||
    userInput.value.lastname == '' ||
    userInput.value.email == '' ||
    userInput.value.phone == ''
  ){
    await dialog.title('Invalid Input').alert('Please fill in all of the fields.');
    return saveProfileStatus.value = null;
  }else{
    const result = await API.post('/auth/user/update', userInput.value);
    if(result.success){
      await dialog.title('Updated Profile').alert('You successfully updated your contact information.');
      return saveProfileStatus.value = null;
    }
  }
  // API.post('')
}

onMounted(async () => {
  const result = await API.post('/auth/user/data');
  if(result.success){
    userInput.value = result.user;
  }
  console.log(result);
  // userInput.value = 
})
</script>
<style lang="scss" scoped>
tr.active {
  /* box-shadow: 0 0 0 1px var(--color-sky); */
  outline: 1px solid var(--color-sky);
  outline-offset: -1px;
  background-color: #f4f8ff !important;
}
tr.active td {
  color: var(--color-sky);
}
.product{
  border-radius:12px;
  --bg-color:#fff;
  box-shadow: 0 3px 10px rgba($color: #000000, $alpha: 0.07);
  overflow:hidden;
  cursor: pointer;
}
.product img{
  height:100px;
  width:100px;
  border-radius: 12px;
  object-fit: cover;
  object-position: center;
  display:block;
}
label{
  padding:12px;
  border-radius:5px;
  background-color:rgba($color: #000000, $alpha: 0.07);
}
label input, label textarea{
  font-size:16px;
  font-family: inherit;
  border:0;
  background-color:transparent;
  resize:none;
  width:100% !important;
  padding: 0;
}
.search{
  background-color:rgba($color: #fff, $alpha: 1);
  border-radius:100px;
  box-shadow: 0 3px 10px rgba($color: #000000, $alpha: 0.07);
}
.v-center {
  align-items: center;
}
.section{
  border-radius: 12px;
    --bg-color: #fff;
    box-shadow: 0 3px 10px rgb(0 0 0 / 7%);
}
.section hr[el]{
  --border: 0;
    --height: 3px;
    --width: 100%;
    --bg-color: black;
    margin: 8px 0;
}
@media (max-width:800px){
  .product img{
    width:100%;
  }
}
</style>
